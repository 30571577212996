import {addChildRoute, addRoutes} from "@pazion/pazion-core/src/router";
import "@pazion/pazion-core/src/config";
import {getCatalogRoutes} from "@pazion/pazion-catalog/src/module-config";

addRoutes(getCatalogRoutes());

/*
 customize...
redirectRouteToBackend('invoices', true);
removeRouteByName('invoices');
redirectRouteToBackend('products', true);
addRoute(  {
  path: '/invoices',
  name: 'invoices',
  component: AppOrderList,
  meta: {
    'show-in-nav': true,
    roles: ['user'],
  },
  });
*/
